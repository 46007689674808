import React, { useState, useEffect } from 'react';
import {
  Typography,
  useTheme,
  Container,
} from '@mui/material';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: '60px 30px',
      flexGrow: 1,
    },
    btnContainer: {
      display: 'flex',
      gap: '24px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      marginBottom: '15px',
    },
    textWrapper_title: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '56.02px',
      color: '#FFFFFF',
      fontFamily: "'Poppins', sans-serif",
      [theme.breakpoints.down('md')]: {
        fontSize: '25px',
      },
    },
    textWrapper_desc: {
      color: '#FFFFFF',
      fontFamily: "'Poppins', sans-serif",
      marginBottom: '1rem',
      fontSize: '14px',
      lineHeight: '25px',
    },
    appsContainerBuyAntiq: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#AB7254',
      borderRadius: '8px',
      marginBottom: theme.spacing(4),
    },
    categoriesBtn: {
      height: '45px',
      backgroundColor: '#FDBB84',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'center',
      color: '#1A202C',
      padding: '0 20px',
      '&:hover': {
        backgroundColor: '#FDBB84',
      },
      borderRadius: '48px',
    },
    customGrid: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '8px',
      [theme.breakpoints.up('lg')]: {
        marginBottom: '15px',
        flexBasis: '49%',
      },
      backgroundColor: '#fedfcf',
    },
    scrollContainer: {
      display: 'flex',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      padding: '1rem',
      scrollbarWidth: 'none', 
      msOverflowStyle: 'none', 
      '&::-webkit-scrollbar': {
        display: 'none', 
      },
      scrollSnapType: 'x mandatory', 
    },
    cardItem: {
      flex: '0 0 auto', 
      width: '298px',
      overflow: 'visible',
      margin: '0 10px', 
      scrollSnapAlign: 'start', 
      textAlign: 'center', 
    },
    productGridContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    sectionTitle: {
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '38.4px',
      color: '#253D4E',
    },
    viewAll: {
      fontWeight: 600,
      fontSize: '25px',
      lineHeight: '21px',
      color: '#AB7254',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  
  }));

const BloginHome = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [blogsContent, setBlogsContent] = useState([]);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get(
          `https://cms.zetaton.dev/api/taqwa-blog-cards?pagination[pageSize]=150&pagination[page]=1`
        );
        console.log(response,'response1')
        setPageContent((prevContent) => [...prevContent, ...response.data.data]);
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    };

    fetchPageContent();
  }, []);

  useEffect(() => {
    const fetchPageBlogs = async () => {
      try {
        const response = await axios.get(
          `https://cms.zetaton.dev/api/Taqwa-Blog-Details?pagination[pageSize]=150&pagination[page]=1`
        );
        setBlogsContent((prevContent) => [...prevContent, ...response.data.data]);
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    };

    fetchPageBlogs();
  }, []);

  const handleCardClick = (blogId) => {
    const matchingContent = blogsContent.find(
      (content) => content.attributes?.blogName === blogId
    );
    if (matchingContent) {
      navigate(`/blogs/${blogId}`);
    } else {
      console.error('No matching content found.');
    }
  };

  const navigateToAllBlogs = () => {
    navigate('/blogs');
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  return (
      <section style={{ margin: '30px 30px 0 30px' }}>
        <div className={classes.productGridContainer}>
          <Typography className={classes.sectionTitle} gutterBottom component="h3" variant="body1">
            Our Blogs
          </Typography>
          <Typography className={classes.viewAll} gutterBottom component="h4" variant="body2" onClick={navigateToAllBlogs}>
            View All
            <FaArrowRight style={{ width: '13.33px', height: '13.33px', color: '#AB7254', marginLeft: '8px' }} />
          </Typography>
        </div>
        <Container style={{ marginTop: '10px' }}>
          <div className={classes.scrollContainer}>
            {pageContent.slice(0, itemsToShow).map((item, index) => (
              <div
                key={index}
                className={classes.cardItem}
                onClick={() => handleCardClick(item?.attributes?.blogName)}
                dangerouslySetInnerHTML={{ __html: item.attributes.Editor }}
              />
            ))}
          </div>
        </Container>
      </section>
  );
};

export default BloginHome;

